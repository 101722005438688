import axios from "axios"
import qs from "qs"
// constants
import { languagesTransformer } from "i18n"
// types
import { Language } from "types/i18n"
import { Pagination } from "types/api"
import { Course } from "types/courses"

export type CmeTestQuestion = {
  id: number
  question: string
  correctAnswerOrder: null | number
  testAnswer: [
    {
      id: number
      answer: string
    }
  ]
}

export type Test = {
  id: number
  attributes: {
    correctAnswersRequired: number
    testDurationText: string
    cmeTestQuestions: []
    questionsCount: number
    cmeCertified: boolean
    course: {
      data: Course
    }
  }
}

export type TestAnswer = {
  id: number
  attributes: {
    questionOrder: number
    answerOrder: number
    course: {
      data: Course
    }
  }
}

export type GetCmeTestRequest = {
  testId: number
  locale?: Language
}

export type GetCmeTestResponse = {
  data: Test
  meta: Pagination
}

export type GetCmeTestNextQuestionRequest = {
  userId: number
  superCourseId: number
  locale?: Language
}

export type GetCmeTestNextQuestionResponse = CmeTestQuestion & {
  questionOrder: number
  testFinished?: boolean
}

export type SubmitCmeTestUserAnswerRequest = {
  userId: number
  superCourseId: number
  questionOrder: number
  answerOrder: number
}

export type SubmitCmeTestUserAnswerResponse = {
  isCorrectAnswer: boolean
}

export type GetCmeTestUserAnswersResultRequest = {
  userId: number
  superCourseId: number
}

export type GetCmeTestUserAnswersResultResponse = {
  isPassed: boolean
  correctAnswersCount: number
  hoursLeftToNextAttempt: number
}

export type GetCmeCertificateRequest = {
  userId: number
  superCourseId: number
  locale?: Language
}

export type GetCmeCertificateResponse = {
  base64Pdf: string
}

export const TEST_KEYS = Object.freeze({
  CME_TEST: "cmeTest",
  CME_TEST_NEXT_QUESTION: "cmeTestNextQuestion",
  CME_TEST_USER_ANSWERS_RESULT: "cmeTestUserAnswersResult",
  CME_CERTIFICATE: "cmeCertificate",
})

export const getCmeTest = async ({ testId, locale = "en" }: GetCmeTestRequest) => {
  const _locale = locale.slice(0, 2) as Language

  const params = qs.stringify(
    {
      locale: languagesTransformer[_locale],
      populate: {
        course: {
          fields: ["courseType", "cmePoints", "cmePointsText", "courseName", "certified"],
          populate: {
            superCourse: {
              fields: ["id"],
            },
          },
        },
        cmeTestQuestions: {
          fields: ["id"],
        },
      },
    },
    { encodeValuesOnly: true }
  )

  const url = `/cme-tests/${testId}?${params}`

  const response = await axios.get<GetCmeTestResponse>(url)

  return response.data
}

export const getCmeTestNextQuestion = async ({
  userId,
  superCourseId,
  locale = "en",
}: GetCmeTestNextQuestionRequest) => {
  const _locale = locale.slice(0, 2) as Language

  const params = qs.stringify(
    {
      userId,
      superCourseId,
      locale: languagesTransformer[_locale],
    },
    { encodeValuesOnly: true }
  )

  const url = `/cme-test-next-question?${params}`

  const response = await axios.get<GetCmeTestNextQuestionResponse>(url)

  return response.data
}

export const submitCmeTestUserAnswer = async ({
  userId,
  superCourseId,
  questionOrder,
  answerOrder,
}: SubmitCmeTestUserAnswerRequest) => {
  const url = `/cme-test-submit-answer`

  const response = await axios.post<SubmitCmeTestUserAnswerResponse>(url, {
    userId,
    superCourseId,
    questionOrder,
    answerOrder,
  })

  return response.data
}

export const getCmeTestUserAnswersResult = async ({ userId, superCourseId }: GetCmeTestUserAnswersResultRequest) => {
  const params = qs.stringify(
    {
      userId,
      superCourseId,
    },
    { encodeValuesOnly: true }
  )

  const url = `/cme-test-answers-result?${params}`

  const response = await axios.get<GetCmeTestUserAnswersResultResponse>(url)

  return response.data
}

export const getCmeCertificate = async ({ userId, superCourseId, locale = "en" }: GetCmeCertificateRequest) => {
  const _locale = locale.slice(0, 2) as Language

  const params = qs.stringify(
    {
      locale: languagesTransformer[_locale],
      userId,
      superCourseId,
    },
    { encodeValuesOnly: true }
  )

  const url = `/cme-certificate?${params}`

  const response = await axios.get<GetCmeCertificateResponse>(url)

  return response.data
}
